<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 class="card-title">Create New Product</h4>
      </div>
      <div class="card-body row">
        <div class="col-12">
          <div class="row">

            <div class="form-group col-3">
              <label>Name</label>
              <fg-input
                type="text"
                name="name"
                v-validate="modelValidations.name"
                :error="getError('name')"
                v-model="model.name"
              ></fg-input>
            </div>

            <div class="form-group col-3">
              <label>Company</label>
              <fg-input
                type="text"
                name="company"
                v-validate="modelValidations.company"
                :error="getError('company')"
                v-model="model.company"
              ></fg-input>
            </div>

            <!-- <div class="form-group col-4">
              <label for>Purchase Date</label>
              <el-date-picker
                name="purchase_date"
                v-model="model.purchase_date"
                type="date"
                placeholder="Pick Date"
                v-validate="modelValidations.purchase_date"
              ></el-date-picker>
            </div> -->

            <div class="form-group col-3">
              <label for>Type</label>
              <el-select
                name="type"
                class="select-default"
                placeholder="Select Type"
                v-model="model.type"
              >
                <el-option
                  v-for="(option, index) in productTypes"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.name} [ ${option.unit} ]`"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="form-group col-3">
              <label>Quantity</label>
              <fg-input
                type="number"
                name="quantity"
                v-validate="modelValidations.quantity"
                :error="getError('quantity')"
                v-model="model.quantity"
              >
                <el-input-number v-model="model.quantity" :min="0"></el-input-number>
              </fg-input>
            </div>

            <div class="form-group col-3">
              <label for>GST Type</label>
              <el-select
                name="GSTType_id"
                class="select-default"
                placeholder="Select Type"
                v-model="model.GSTType_id"
                @change="updateGST()"
              >
                <el-option
                  v-for="(option, index) in gstTypes"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </div>

            <div class="form-group col-3">
              <label>MRP</label>
              <fg-input
                type="number"
                name="purchase_price"
                v-validate="modelValidations.purchase_price"
                :error="getError('purchase_price')"
                v-model="model.purchase_price"
              ></fg-input>
            </div>

            <div class="form-group col-3">
              <label>MRP with GST</label>
              <fg-input
                readonly
                type="number"
                name="sale_price"
                v-validate="modelValidations.sale_price"
                :error="getError('sale_price')"
                v-model="mrp_with_gst"
              ></fg-input>
            </div>

            <!-- <div class="form-group col-3">
              <label>Margin</label>
              <fg-input readonly type="number" name="margin" v-model="margin"></fg-input>
            </div> -->

            <div class="form-group col-3">
              <label>Supplier Name</label>
              <fg-input
                type="text"
                name="supplier_name"
                v-validate="modelValidations.supplier_name"
                :error="getError('supplier_name')"
                v-model="model.supplier_name"
              ></fg-input>
            </div>

            <div class="form-group col-3">
              <label>HSN Code</label>
              <fg-input
                type="text"
                name="hsn"
                v-validate="modelValidations.hsn"
                :error="getError('hsn')"
                v-model="model.hsn"
              ></fg-input>
            </div>

          </div>
        </div>

        <div class="col-4" v-if="false">
          <div class="picture-container">
              <el-upload
                class="picture"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="handlePreview"
                :auto-upload="false"
                :show-file-list="false"
              >
              <img v-if="model.imageUrl" :src="model.imageUrl" class="picture-src">
            </el-upload>
            <p-button type="default" block size="sm" outline round>Choose Product Picture</p-button>
          </div>
        </div>
        <!-- end of new location creation -->
      </div>
      <div class="card-footer text-right">
        <p-button type="info" @click.prevent="validate">Create</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Upload, DatePicker, Select, Option, InputNumber } from "element-ui";
import { mapGetters } from "vuex";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option,
    [InputNumber.name]: InputNumber
  },
  data() {
    return {
      model: {
        name: "",
        company: "",
        purchase_date: "",
        type: "",
        GST: null,
        GSTType_id: "",
        purchase_price: 0,
        sale_price: 0,
        margin: 0,
        supplier_name: "",
        quantity: 0,
        hsn: '',
        imageUrl: "/static/img/box.png"
      },
      modelValidations: {
        name: {
          required: true
        },
        company: {
          required: true
        },
        purchase_date: {
          required: true
        },
        type: {
          required: true
        },
        GSTType_id: {
          required: true
        },
        // gst: {
        //   required: true
        // },
        purchase_price: {
          required: true
        },
        sale_price: {
          required: true
        },
        supplier_name: {
          required: true
        },
        quantity: {
          required: true,
          min: 0
        },
        hsn: {
          required: true
        }
      }
    };
  },
  mounted(){
    this.$store.dispatch('fetchProductTypesAll')
    this.$store.dispatch('fetchGSTTypesAll')
  },
  computed: {
    ...mapGetters({
      productTypes: "getProductTypes",
      gstTypes: "getGSTTypes"
    }),
    margin() {
      return this.model.sale_price - this.model.purchase_price;
    },
    mrp_with_gst(){
      if(this.model.GST){
        return Math.round(parseFloat(this.model.purchase_price) + parseFloat(this.model.purchase_price * (this.model.GST.percentage/100)));
      }
      else 
        return 0;
    }
  },
  methods: {
    handlePreview(file) {
      this.model.imageUrl = URL.createObjectURL(file.raw);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        this.$emit("on-submit", this.registerForm, isValid);
        if(isValid){
          this.model.GSTType_id = this.model.GST.id
          this.model.sale_price = this.mrp_with_gst
          // console.log(this.model);
          this.$store.dispatch("createProduct", this.model);
        }
      });
    },
    updateGST() {
      this.model.GST = this.gstTypes[
        this.gstTypes.findIndex(element => {
          return element.id == this.model.GSTType_id;
        })
      ];
    },
  }
};
</script>

<style>
  .picture{
    margin-bottom: 30px;
  }
  
  .picture-container{
    text-align: center;
  }

  .el-input-number .el-input__inner{
    outline: none;
  }

  .btn-primary, .form-group .el-input-number .plus-button, .form-group .el-input-number .el-input-number__decrease, .form-group .el-input-number .el-input-number__increase{
    background-color: #66615B;
    color: #fff;
  }

  .form-group .el-input-number .el-input-number__increase{
    border-radius: 0 5px 5px 0 !important;
  }

  .form-group .el-input-number .el-input-number__decrease{
    border-radius: 5px 0px 0px 5px !important;
  }

  .select-default.el-select .el-input input{
    border-radius: 5px;
  }
</style>

